/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

.error-404 {
	display: grid;
	grid-gap: 40px;
	gap: 40px;

	@include min(800px) {
		grid-template-columns: 300px 1fr;
	}

	@include min(1024px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		gap: 50px;
	}

	.error-404__flair {
		font-size: 28vw;
		font-family: $font-headings;
		color: $color-brand;

		@include min($max-width) {
			font-size: 320px;
		}
	}
}