/*==============================
	FONTS
==============================*/

$font-monospace: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono",
	Menlo, Courier, monospace;

$font-body-name: "Montserrat";
$font-headings-name: "Montserrat";

$font-body: $font-body-name, system-ui, sans-serif;
$font-headings: $font-headings-name, Poppins, Futura, sans-serif;
$font-serif: mrs-eaves, serif;
$font-serif-sc: mrs-eaves-roman-small-caps, sans-serif;

$imagePath: "/assets/images/";
$fontPath: "/assets/fonts/";

/*==============================
	COLORS
==============================*/

$color-base: hsl(0, 0%, 14%);
$color-brand: #1d2337;
$color-brand-darker: #0d1322;
$color-secondary: #84c450;
$color-light: hsl(26, 28%, 95%);
$color-dark: hsl(0, 0%, 3%);
$color-neutral: #e1e3e3;

$gradient-secondary: linear-gradient(to right, #84c450, #1b5962);

$color-neutral-100: hsl(0, 0%, 97%);
$color-neutral-200: hsl(0, 0%, 95%);
$color-neutral-300: hsl(0, 0%, 89%);
$color-neutral-400: hsl(0, 0%, 79%);
$color-neutral-500: hsl(0, 0%, 70%);
$color-neutral-600: hsl(0, 0%, 49%);
$color-neutral-700: hsl(0, 0%, 35%);
$color-neutral-800: hsl(0, 0%, 22%);
$color-neutral-900: hsl(0, 0%, 9%);

/*==============================
	SIZES
==============================*/

$max-width: 1586px;

/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: hsl(218, 76%, 67%);
$form-active-text: hsl(0, 0%, 100%);

$form-item-standard-size: 46px;
$form-item-xl-size: 64px;
$form-item-border-color: $color-neutral-400;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: hsl(0, 0%, 100%);
$form-item-fg: $color-neutral-900;
$form-disabled-item-border-color: $color-neutral-300;
$form-disabled-item-bg: $color-neutral-200;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, 0.12);
$form-icon-color: $color-neutral-400;

/*==============================
	BUTTONS
==============================*/

$button-radius: 50px;
$button-bg: $color-brand;
$button-fg: #fff;
$button-primary-bg: $color-secondary;
$button-primary-fg: $color-dark;
