@use "sass:string";

/*****************************************
* Blackwell - MIXINS
*****************************************/

@mixin grid($breakpointMap...) {
	$nextMapIndex: 1;
	$firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);
	$gap: 0;

	@each $breakpoint in $breakpointMap {
		$nextMapIndex: $nextMapIndex + 1;
		$nextMapValue: if(
			length($breakpointMap) >= $nextMapIndex,
			nth(nth($breakpointMap, $nextMapIndex), 1),
			false
		);

		$currentBreakpoint: nth($breakpoint, 1);
		$amount: nth($breakpoint, 2);
		$gap: if(length($breakpoint) == 3, nth($breakpoint, 3), $gap);

		@if $nextMapValue != false {
			@include minmax($currentBreakpoint, $nextMapValue) {
				grid-template-columns: repeat($amount, 1fr);
				grid-gap: $gap;
				gap: $gap;
			}
		} @else {
			@include min($currentBreakpoint - 1px) {
				grid-template-columns: repeat($amount, 1fr);
				grid-gap: $gap;
				gap: $gap;
			}
		}
	}
}

@mixin image-set($fallback, $set...) {
	background-image: url($fallback);
	background-image: image-set($set);
}

@mixin link($selector: a, $visited: true) {
	@if $selector != & {
		$selector: string.unquote($selector);

		@if $visited == true {
			#{$selector},
			#{$selector}:link,
			#{$selector}:visited {
				@content;
			}
		} @else {
			#{$selector},
			#{$selector}:link {
				@content;
			}
		}
	} @else {
		&,
		&:link,
		&:visited {
			@content;
		}
	}
}

@mixin link-visited($selector: a) {
	@if $selector != & {
		$selector: string.unquote($selector);

		#{$selector}:visited {
			@content;
		}
	} @else {
		&:visited {
			@content;
		}
	}
}

@mixin link-over($selector: a) {
	@if $selector != & {
		$selector: string.unquote($selector);

		#{$selector}:hover,
		#{$selector}:focus,
		#{$selector}:active {
			@content;
		}
	} @else {
		&:hover,
		&:focus,
		&:active {
			@content;
		}
	}
}

@mixin link-all($selector: a) {
	@if $selector != & {
		$selector: string.unquote($selector);

		#{$selector},
		#{$selector}:link,
		#{$selector}:visited,
		#{$selector}:hover,
		#{$selector}:focus,
		#{$selector}:active {
			@content;
		}
	} @else {
		&,
		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			@content;
		}
	}
}

@mixin min($value, $px: false) {
	@if $px == false and unit($value) == px {
		$value: rem($value);
	}

	@media screen and (min-width: $value) {
		@content;
	}
}

@mixin max($value, $px: false) {
	@if $px == false and unit($value) == px {
		$value: rem($value);
	}

	@media screen and (max-width: $value) {
		@content;
	}
}

@mixin minmax($value-min, $value-max, $px: false) {
	@if $px == false {
		@if unit($value-min) == px {
			$value-min: rem($value-min);
		}
		@if unit($value-max) == px {
			$value-max: rem($value-max);
		}
	}

	@media screen and (min-width: $value-min) and (max-width: $value-max) {
		@content;
	}
}

@mixin quantity-query($condition) {
	$conditionComplexity: length($condition);

	$least: nth($condition, 2);
	$selectorMap: ();

	@each $s in & {
		$l: length($s);
		$last: nth($s, $l);

		$s: implode($s, " ");

		@if $conditionComplexity == 2 {
			$cond: nth($condition, 1);

			@if $cond == min {
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(n+#{$least})",
					space
				);
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(n+#{$least}) ~ #{$last}",
					space
				);
			} @else if $cond == max {
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(-n+#{$least})",
					space
				);
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(-n+#{$least}) ~ #{$last}",
					space
				);
			} @else if $cond == exactly {
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child",
					space
				);
				$selectorMap: append(
					$selectorMap,
					"#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child ~ #{$last}",
					space
				);
			}
		} @else {
			$most: nth($condition, 4);
			$selectorMap: append(
				$selectorMap,
				"#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child",
				space
			);
			$selectorMap: append(
				$selectorMap,
				"#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child ~ #{$last}",
				space
			);
		}
	}

	$compound: implode($selectorMap, ", ");

	@at-root {
		#{$compound} {
			@content;
		}
	}
}

@mixin fill($top: 0, $right: 0, $bottom: 0, $left: 0, $position: absolute) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin pad($color, $side: left, $element: before, $width: 999em) {
	$from: if($side == right, left, right);

	&::#{$element} {
		content: "";
		position: absolute;
		width: $width;
		#{$from}: 100%;
		top: 0;
		bottom: 0;
		background-color: $color;
	}
}

@mixin headings($from, $through) {
	$start: clamp-value($from, 1, 6);
	$end: clamp-value($through, 1, 6);
	$selectors: ();

	@for $level from $start through $end {
		$selectors: append($selectors, "h#{$level}", comma);
	}

	$compound: implode($selectors, ", ");

	#{$compound} {
		@content;
	}
}
