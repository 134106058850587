/*
* BASE DEPENDENCIES
*/ /*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
.error-404 {
  display: grid;
  grid-gap: 40px;
  gap: 40px;
}
@media screen and (min-width: 50rem) {
  .error-404 {
    grid-template-columns: 300px 1fr;
  }
}
@media screen and (min-width: 64rem) {
  .error-404 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    gap: 50px;
  }
}
.error-404 .error-404__flair {
  font-size: 28vw;
  font-family: "Montserrat", Poppins, Futura, sans-serif;
  color: #1d2337;
}
@media screen and (min-width: 99.125rem) {
  .error-404 .error-404__flair {
    font-size: 320px;
  }
}